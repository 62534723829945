import axios from './axios';
import qs from 'qs';
// import moment from 'moment';
// import JLAlert from '@/components/jl-alert';
// import JLButton from '@/components/jl-button';

// import {VueCookieNext} from 'vue-cookie-next';
class API { 
    constructor() {
        console.log('API LOADED');
    }

    async login(data) {
        return await axios.post('/auth', qs.stringify(data))
                        .then(res => {
                            if(res.status === 200) {
                                localStorage.setItem('ardentpmp_data', JSON.stringify(res.data));
                                window.location.reload(true);
                                return;
                            }
                            return res;
                        })
                        .catch(err => {
                            return err;
                        });
    }

    async dashboard() {
        return await axios.get('/header')
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }

    async ME() {
        return await axios.get('/me')
                .then(res => {
                    // console.log(res.data)
             
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }

    iniClick() {
        let btns = document.querySelectorAll('[data-block-toggle]');
        if(btns.length) {
            btns.forEach(element => {
                element.addEventListener('click', () => {
                    let classname = element.getAttribute('data-block-toggle');
                    let obj = document.querySelector(classname);
                    if(!element.classList.contains('ks-open')) {
                        element.classList.add('ks-open');
                        obj.classList.add('ks-open');
                    }
                    else {
                        element.classList.remove('ks-open');
                        obj.classList.remove('ks-open');
                    }
                });
            });
        }
    }

    async loadPartners(params = {}, type = 'erm', header = false) {
        var esc = encodeURIComponent;
        var query = Object.keys(params)
                    .map(k => esc(k) + '=' + esc(params[k]))
                    .join('&');

        return await axios.get(`/partners/${type}${(header ? '/header/' : '')}${(query !== '' ? `?${query}` : '')}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }

    async loadBusinessType() {
        return await axios.get('/business_type')
                        .then(res => {
                            return res.data;
                        })
                        .catch(() => {
                            return {};
                        });
    } 

    async loadIndustry() {
        return await axios.get('/industry')
                        .then(res => {
                            return res.data;
                        })
                        .catch(() => {
                            return {};
                        });
    } 

    async loadNWC() {
        return await axios.get('/nwc')
                        .then(res => {
                            return res.data;
                        })
                        .catch(() => {
                            return {};
                        });
    }

    async updatePartner(data, type = 'erm') {
        return await axios.post(`/partners/${type}`, qs.stringify(data));
    }

    async loadReport(params = {}, type = 'accrual', header = false) {
        var esc = encodeURIComponent;
        var query = Object.keys(params)
                    .map(k => esc(k) + '=' + esc(params[k]))
                    .join('&');

        return await axios.get(`/report/${type}${(header ? '/header/' : '')}${(query !== '' ? `?${query}` : '')}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return [];
                })
    }

    async contact(id) {
        return await axios.get(`/contact/${id}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }

    async contact_post(data, id = '') {
        return await axios.post(`/contact/${id}`, qs.stringify(data));
    }

    async funnel(id) {
        return await axios.get(`/funnel/${id}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }
    
    async funnel_post(data, id) {
        return await axios.post(`/funnel/${id}`, qs.stringify(data));
    }

    async loadContacts(params = {}, type = 'all', header = false) {
        var esc = encodeURIComponent;
        var query = Object.keys(params)
                    .map(k => esc(k) + '=' + esc(params[k]))
                    .join('&');

        return await axios.get(`/contact/${type}${(header ? '/header/' : '')}${(query !== '' ? `?${query}` : '')}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return {};
                })
    }

    // async send(data = {}) {
    //     return await axios.post('/campaign/send', qs.stringify(data))
    // }

    async logout(){
        return await axios.get(`/logout`)
    }

    async updateCRF(data = {}){
            return await axios.post('/updatecrf', qs.stringify(data))
    }
    
    async loadcrf(data = {}){

        return await axios.post(`/loadcrf`, qs.stringify(data))
        .then(res => {       
            // console.log(res.data)

            return res.data
        })
        .catch(() => {
            return {}
        })
   
    }
    async downloadCRF(data = {}){
        return await axios.post('/downloadcrf',qs.stringify(data))

    }

    async approveCRF(data = {}){
        return await axios.post('/approvecrf',qs.stringify(data))
    }
    async disapproveCRF(data = {}){
        return await axios.post('/disapprovecrf',qs.stringify(data))

    }

    async fetchpayee(data = {}){
        return await axios.post(`/fetchpayee`,qs.stringify(data))
        .then(res => {
           
            return res.data
        })
        .catch(()=> {
            return{}
        })
    }

    async fetchdepartment(){
        return await axios.get(`fetchdepartment`)
        .then(res => {
        
            return res.data
        })
        .catch(() => {
            return{}
        })
    }

    async fetchUsers(){
        return await axios.get(`fetchusers`)
        .then(res => {
            // console.log(res.data)

            return res.data
        })
        .catch(() => {
            return{}
        })
    }

    async retrieveQuote(data = {}){
        return await axios.post(`quoteretrieve`, qs.stringify(data))
        .then(res => {
            // console.log(data)
            
     
            return res.data
        })
        .catch(() => {
            return{}
        })
    }

    async approveQoute(data = {}){
        return await axios.post(`approveqt`, qs.stringify(data))
    }

    async saveCRF(data = {}){
        return await axios.post(`savecrf`, qs.stringify(data))
    }

    async sendCRF(data = {}){
        return await axios.post(`sendcrf`, qs.stringify(data))
    }
    async cancelCRF(data = {}){
        return await axios.post(`cancelcrf`, qs.stringify(data))
    }

    async reverseCRF(data = {}){
        return await axios.post(`reversecrf`, qs.stringify(data))

    }

    async uploadFile(data = {},cr_no){
        return await axios.post(`uploadfiles`, qs.stringify({data:data,cr_no:cr_no}))
    }
    async getfile(data = {}){
        return await axios.post(`getfile`, qs.stringify(data))
    }

    async prsCreate(prs_header,prs_details,prs_suppliers){
        return await axios.post(`prsinsert`,qs.stringify({prs_header:prs_header,prs_details:prs_details,prs_suppliers:prs_suppliers}))
    }
    async prsUpdate(prs_header,prs_details,prs_suppliers){
        return await axios.post(`prsupdate`,qs.stringify({prs_header:prs_header,prs_details:prs_details,prs_suppliers:prs_suppliers}))
    }
    async retrievePRS(data = ''){
        return await axios.post(`prsretrieve`,qs.stringify({searcher:data}))
    }
    async getSoa(data = ''){
        return await axios.post(`soaretrieve`,qs.stringify({searcher:data}))
    }

    async createSoa(data = ''){
        return await axios.post(`createsoa`,qs.stringify(data))
    }

    async downloadSOA(data = ''){
        return await axios.post(`downloadsoa`,qs.stringify(data))
    }
    
    async updateSoa(data = ''){
        return await axios.post(`updatesoa`,qs.stringify(data))
    }


    async testpdf(data = ''){
        return await axios.post(`testpdf`,qs.stringify(data))
    }

    // getDate(){
    //     // let today = new Date().toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"})

    //     // var date_now = (today.getFullYear() + ' ' + (today.getMonth() + 1) + ',' + today.getDate())
    //     let now = moment();
    
    //     return now.format('ll');

    // }
    // convertDate(data){
    //     let date = new Date(data)
    //     var today = date.getDate()
    //     var month = date.getMonth() + 1

    //     if (month < 10 ){
    //         month =  '0' + month 
    //     }
    //     if(today < 10){
    //         today = '0' + today
    //     }
    //     // var date_now = (today.getFullYear() + ' ' + (today.getMonth() + 1) + ',' + today.getDate())
    //     var date_now = (date.getFullYear() + '-' + month + '-' + today)

    //     return date_now

    // }
    // converDateToString(data){
    //     let date = new Date(data)
    //     let string = date.toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"})
    //     return string

    // }
    // _initDate(n){
    //     let date = new Date()

    //     var month = date.getMonth() + n
    //     var year = date.getFullYear() 
    //     var today = date.getDate()

    //     if( n == 2){
    //         if(month == 2) {
    //             if(today > 28){
    //                 today = 28
    //             } 
    //         }
    //     }
     

    //     // var month = date.getMonth() + n
    //     // var year = date.getFullYear()
    //     // var today = date.getDate()


    //     if(month == 0){
    //         year = year - 1
    //         month = 12
    //     }
    //     if(month == 13){
    //         month = month - 12
    //         year = year + 1
    //     }
    //     if(today < 10){
    //         today = '0' + today
    //     }
    //     if(month  < 10){      
    //         if(month == 0){
    //             month = '0' + 1
    //         }
    //         else {
    //             month = '0' + month 
    //         }
    //     }

        
    //     return (year + '-' + month + '-' + today)
        
    // }

    // reformat(data){
    //     let amount =  data;
    //     let length = data.length

    //     length = length / 6
        
    //     if(length > 0){
    //         for(var i = 0; i < length ; i ++){
    //             amount = amount.replace(',','');
    //         }

    //     }

    //     return amount * 1;
    // }

    // momentDate(){
    //     let now = moment();
    //     return now.subtract(30, 'day').format('LL')
    //     // return now.format('ll');
    // }


}

export default new API();