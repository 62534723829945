import axios from 'axios';
import { VueCookieNext } from 'vue-cookie-next'
import jlalert from '@/components/jl-alert';

//import helper from '@/components/mitt.helper';

let baseURL;
let AUTHNAME = process.env.VUE_APP_AUTHNAME;
let XID = process.env.VUE_APP_XID;
let CSRF = process.env.VUE_APP_CSRF;

if(process.env.NODE_ENV == 'production') baseURL = process.env.VUE_APP_ROOTAPI;
else baseURL = '/api/';

const request = axios.create({
    baseURL,
})

request.interceptors.request.use((config) => { 
    if(VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEXID)) config.headers.common[XID] = VueCookieNext.getCookie(process.env.VUE_APP_COOKIEXID);
    if(VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEAUTH)) config.headers.common[AUTHNAME] = VueCookieNext.getCookie(process.env.VUE_APP_COOKIEAUTH);
    if(VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIECSRF)) {
        config.headers.common[CSRF] = VueCookieNext.getCookie(process.env.VUE_APP_COOKIECSRF);
        config.data += (config.data ? '&' : '')+`${process.env.VUE_APP_COOKIECSRF}=${VueCookieNext.getCookie(process.env.VUE_APP_COOKIECSRF)}`;
    }
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    config.headers.common[process.env.VUE_APP_SRCNAME] = (isMobile ? 'mobile' : 'pc');

    return config;
}, (error) => {
    return Promise.reject(error);
});


request.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {

    console.log(error)

    if(error.response.hasOwnProperty.call(error.response, "status") == undefined) {
        return Promise.reject("Please check your internet connection");
    }

    if(error.response.status == 401) {
        VueCookieNext.removeCookie(process.env.VUE_APP_COOKIEXID);
        VueCookieNext.removeCookie(process.env.VUE_APP_COOKIEAUTH);
        //helper.emitter.emit('logged_out');
        // window.location.reload(true);
        // jlalert.emitter.emit('show', ['Unauthorized Access']);
        return Promise.reject('Expired session, please relogin');
    }

    if(error.response.status == 404) {
        jlalert.emitter.emit('show', ['Invalid Gateway']);
        return Promise.reject("Invalid Gateway");
    }

    if(error.response.status == 405) {
        jlalert.emitter.emit('show', ['Method not Allowed']);
        return Promise.reject("Method not allowed");
    }

    if (error.response && error.response.data && error.response.status != 500) {
        jlalert.emitter.emit('show', [error.response.data]);
        return Promise.reject(error.response.data);
    }
    
    jlalert.emitter.emit('show', [error.response.statusText]);
    return Promise.reject(error.response.statusText);
  }
);

export default request;