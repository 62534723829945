import moment from 'moment';
// import JLAlert from '@/components/jl-alert';
// import JLButton from '@/components/jl-button';

// import {VueCookieNext} from 'vue-cookie-next';


// moment.locale();         // en
// moment().format('LT');   // 8:20 AM
// moment().format('LTS');  // 8:20:47 AM
// moment().format('L');    // 04/26/2023
// moment().format('l');    // 4/26/2023
// moment().format('LL');   // April 26, 2023
// moment().format('ll');   // Apr 26, 2023
// moment().format('LLL');  // April 26, 2023 8:20 AM
// moment().format('lll');  // Apr 26, 2023 8:20 AM
// moment().format('LLLL'); // Wednesday, April 26, 2023 8:20 AM
// moment().format('llll');  // Wed, Apr 26, 2023 8:20 AM
let now = moment();
class HELPER { 
    constructor() {
        console.log('HELPER LOADED');
    }

    formatedDate(){

        let date = now;
        // return date.subtract(days, 'day').format('LL')
        return date.format('ll');
    }


    subtractedDate(days){
  
        
        let date = now;
        let data = date.subtract(days, 'day').format('YYYY-MM-DD');
        return data
    }

    addDate(days){


        let date = now;
        let data = date.add(days, 'day').format('YYYY-MM-DD');
        return data
    
    }

    
    stringDate(data){


        return moment(data).format('LL'); 
    }

    reformatDate(data){

        return moment(data).format('YYYY-MM-DD');
    }

    dateToday(){

        return moment().format('YYYY-MM-DD');
    }


    reformat(data){
        let amount =  data;
        let length = data.length

        length = length / 6
        
        if(length > 0){
            for(var i = 0; i < length ; i ++){
                amount = amount.replace(',','');
            }

        }

        return amount * 1;
    }



}

export default new HELPER();