<template>
    <div class="toast d-flex align-items-center text-white border-0" role="alert" aria-live="assertive" aria-atomic="true"
     :class="{'show':animate,'bg-success':!error,'bg-danger':error}" v-if="visible">
        <div class="toast-body fw-500">
            {{text}}
        </div>
        <button type="button" class="btn-close btn-close-white ms-auto me-2" data-bs-dismiss="toast" aria-label="Close" @click="hide()"></button>
    </div>
</template>

<script>
import alertjl from './';

export default {
  data() {
    return {
        visible: false, 
        animate: false,
        text: '',
        timer: {},
        error: true
    }
  },
  methods: {
    hide() {
        this.animate = false;
        if(this.timer) clearTimeout(this.timer);
        setTimeout(() => {
            this.visible = false;
            this.text = '';
        }, 100);
    },
    show(params) {
      this.visible = true;
      setTimeout(() => {
        this.animate = true;
        this.text = (params[0] == undefined ? params : params[0]);
        this.error = (params[1] == undefined ? true : params[1]);   
        
        if(this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.hide(); 
        }, 3000);
      }, 100);
    }
  },
  beforeMount() {
    alertjl.emitter.on('show',(text, error) => {
        this.show(text, error);
    });
  }
}
</script>

<style scoped>
.toast.show {
    top: 10px;
    right: 10px;
}

.toast {
  position: fixed;
  padding-left: 20px;
  bottom: -52px;
  width: 40vw;
  transition: 0.1s;
  border-radius: 0;
  z-index: 999999;
  height: 52px; 
  border-radius: 4px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

@media screen and (max-width: 991px) {
  .toast {
    max-width: 100%;
    width: 100%;
    border-radius: 0;
  }

  .toast.show {
    top: unset;
    bottom: 0;
    right: unset;
  }
}

.btn-close {
  border: 0;
  background: transparent;
  margin-left: auto;
}

.btn-close::before, .btn-close::after {
  position: absolute;
  content: ' ';
  right: 20px;
  top: 20px;
  height: 12px;
  width: 2px;
  background-color: #fff;
}
.btn-close:before {
  transform: rotate(45deg);
}
.btn-close:after {
  transform: rotate(-45deg);
}

</style>