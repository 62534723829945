import { createWebHistory, createRouter } from "vue-router";

let title = process.env.VUE_APP_APPNAME;

const routes = [
  {
    path: "/",
    component: () => import('@/views/Layout'),
    children: [
      {
        path : "/",
        name : "home",
        alias : ["/home"],
        components : {
          main:  () => import('@/views/Home')
        },
        meta : {
          title : `Home - ${title}`
        }
      },
      {
          path: "/hris",
          name: "hris",
          components: {
              main: () => import('@/views/HRIS')
          },
          meta: {
            title: `HRISV3 - ${title}`
          }
        },
        {
          path:"/crf",
          name:"crf",
          components: {
            main:() => import('@/views/CRF_view')
          },
          meta : {
            head : `CRF`
          },
          children : [
            {
              path: "/crf",
              name: "active",
              components : {
                main:() => import ('@/views/crf/crf_active')
              },
              meta : {
                title : `CRF Active -${title}` 
              }
            },
            {
              path: "/crf/approval",
              name: "approval",
              components : {
                main:() => import ('@/views/crf/crf_active')
              },
              meta : {
                title : `CRF Approval -${title}` 
              }
            },
            {
              path: "/crf/posting",
              name: "posting",
              components : {
                main:() => import ('@/views/crf/crf_active')
              },
              meta : {
                title : `CRF Posting -${title}` 
              }
            },
            {
              path: "/crf/apv",
              name: "PADNJOapproval",
              components : {
                main:() => import ('@/views/crf/crf_active')
              },
              meta : {
                title : `PA/DN/JO approval -${title}` 
              }
            },
            {
              path: "/crf/create",
              name: "create",
              components : {
                main:() => import ('@/views/crf/crf_create')
              },
              meta : {
                title : `CRF Create -${title}` 
              }
            }
          ]
        },
        {
          path:'/quotation',
          name:'quotation',
          components: {
            main:() => import ('@/views/Quotation_view')
          },
          meta : {
            head : 'Quotation'
          },
          children: [
            {
              path: '/quotation',
              name: 'quotation approval',
              components : {
                main:() => import('@/views/quotation/quotation_approval')
              },
              meta : {
                title : `Quotation Approval -${title}`
              }
            }
          ]
        },
        {
          path: "/prs",
          name: "prsnmd",
          components: {
              main: () => import('@/views/prs')
          },
          meta: {
            title: `PRS - ${title}`
          }
        },
        {
          path : '/soa',
          name : 'soa',
          components : {
            main : () => import('@/views/soa')
          },
          meta : {
            title : `SOA - ${title}`
          }
        },
        // {
        //   path: "/hris",
        //   name: "hris",
        //   components: {
        //       main: () => import('@/views/HRIS')
        //   },
        //   meta: {
        //     title: `HRISV3 - ${title}`
        //   }
        // },
        // {
        //   path: "/campaign",
        //   name: "campaign",
        //   components: {
        //       main: () => import('@/views/Campaign')
        //   },
        //   meta: {
        //     title: `Campaign - ${title}`
        //   }
        // },
        // {
        //   path: "/partners",
        //   components: {
        //       main: () => import('@/views/PartnersLayout')
        //   },
        //   meta: {
        //       head: 'Partners'
        //   },
        //   children: [
        //     {
        //       path: "/partners",
        //       name: "partners",
        //       components: {
        //           partner_view: () => import('@/views/partners/ERMPartners')
        //       },
        //       meta: {
        //         title: `ERM Partners - ${title}`
        //       }
        //     },
        //     {
        //       path: "/partners/pmp",
        //       name: "partners/pmp",
        //       components: {
        //           partner_view: () => import('@/views/partners/PMPPartners')
        //       },
        //       meta: {
        //         title: `PMP Partners - ${title}`
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: "/reports",
        //   redirect: "/reports/accrual",
        //   components: {
        //       main: () => import('@/views/ReportsLayout')
        //   },
        //   meta: {
        //     head: 'Reports'
        //   },
        //   children: [
        //       {
        //           path: "/reports/accrual",
        //           name: "reports/accrual",
        //           components: {
        //               report_view: () => import('@/views/report/ReportAccrual')
        //           },
        //           meta: {
        //             title: `Accrual Report - ${title}`
        //           }
        //       },
        //       {
        //         path: "/reports/bday",
        //         name: "reports/bday",
        //         components: {
        //             report_view: () => import('@/views/report/ReportBday')
        //         },
        //         meta: {
        //           title: `Contact Birthday Celebrants - ${title}`
        //         }
        //       },
        //       {
        //         path: "/reports/funnel",
        //         name: "reports/funnel",
        //         components: {
        //             report_view: () => import('@/views/report/ReportSalesFunnel')
        //         },
        //         meta: {
        //           title: `Sales Funnel - ${title}`
        //         }
        //       },
        //       {
        //         path: "/reports/item",
        //         name: "reports/item",
        //         components: {
        //             report_view: () => import('@/views/report/ReportSalesItem')
        //         },
        //         meta: {
        //           title: `Sales Funnel - ${title}`
        //         }
        //       },
        //   ]
        // },
        {
          path: "/contacts",
          components: {
              main: () => import('@/views/ContactsLayout')
          },
          meta: {
              head: 'Contacts'
          },
          children: [
            {
              path: "/contacts",
              name: "all",
              components: {
                  contact_view: () => import('@/views/contacts/Contacts.vue')
              },
              meta: {
                title: `All Contacts - ${title}`
              }
            },
            {
              path: "/contacts/me",
              name: "me",
              components: {
                  contact_view: () => import('@/views/contacts/Contacts.vue')
              },
              meta: {
                title: `My Contacts - ${title}`
              }
            },
            {
              path: "/contacts/duplicate",
              name: "duplicate",
              components: {
                  contact_view: () => import('@/views/contacts/Contacts.vue')
              },
              meta: {
                title: `Duplicate Contacts - ${title}`
              }
            },
          ]
        }, 
    ],
    meta: {
        auth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/Login'),
    meta: {
        title: `Login - ${title}`,
        auth: false
    }
  },
  
  {
      path: "/:pathMatch(.*)*",
      name: "error",
      component: () => import('@/views/Error'),
      meta: {
          error: true
      }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
