import JLButtonView from './JLButton.vue';
import mitt from 'mitt';
const emitter = mitt();

const JLButton = {
    install(Vue) {
        Vue.component('jl-button', JLButtonView);
        Vue.config.globalProperties.$jlbutton = {
            unload(params) {
                emitter.emit('jlunload', params);
            }
        }
    },
    emitter
}

export default JLButton