<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
    watch: {
        "$route": function() {
            let body = document.body;
            body.classList.remove('ks-right-bar-open');
        }
    }
}
</script>

<style>
:root {
    --primary-color: #e43834;
    --sub-color: #a92522;
    scroll-behavior: unset !important;
}

*,*::before,*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background: #fff !important;
}
html, body, #app {
    width: 100%;
    height: 100%;
}

html, body {
    font-size: 14px;
}

a {
  text-decoration: none !important;
}

.fs-8 {
  font-size: 8px !important;
}.fs-9 {
  font-size: 9px !important;
}.fs-10 {
  font-size: 10px !important;
}.fs-11 {
  font-size: 11px !important;
}.fs-12 {
  font-size: 12px !important;
}.fs-13 {
  font-size: 13px !important;
}.fs-14 {
  font-size: 14px !important;
}.fs-15 {
  font-size: 15px !important;
}.fs-16 {
  font-size: 16px !important;
}.fs-17 {
  font-size: 17px !important;
}.fs-18 {
  font-size: 18px !important;
}.fs-19 {
  font-size: 19px !important;
}.fs-20 {
  font-size: 20px !important;
}.fs-21 {
  font-size: 21px !important;
}.fs-22 {
  font-size: 22px !important;
}.fs-23 {
  font-size: 23px !important;
}.fs-24 {
  font-size: 24px !important;
}

.fw-100 {
  font-weight: 100 !important;
}.fw-200 {
  font-weight: 200 !important;
}.fw-300 {
  font-weight: 300 !important;
}.fw-400 {
  font-weight: 400 !important;
}.fw-500 {
  font-weight: 500 !important;
}.fw-600 {
  font-weight: 600 !important;
}.fw-700 {
  font-weight: 700 !important;
}.fw-800 {
  font-weight: 800 !important;
}.fw-900 {
  font-weight: 900 !important;
}

.opacity-1 {
  opacity: 0.1;
}.opacity-2 {
  opacity: 0.2;
}.opacity-3 {
  opacity: 0.3;
}.opacity-4 {
  opacity: 0.4;
}.opacity-5 {
  opacity: 0.5;
}.opacity-6 {
  opacity: 0.6;
}.opacity-7 {
  opacity: 0.7;
}.opacity-8 {
  opacity: 0.8;
}.opacity-9 {
  opacity: 0.9;
}

.flex-0 {
    flex: 0 !important;
}
.flex-1 {
    flex: 1 !important;
}

input::placeholder {
    opacity: 0.4;
}

button, button:active, button:focus,.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus, .btn-check:focus+.btn-primary, .btn-primary:focus, input.custom-input:focus, input.custom-input:active,.btn-check:focus+.btn, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.custom-input {
    border: 0 !important;
    background: #f9f9f9 !important;
    transition: 0.3s !important;
    color: #757575 !important;
    font-weight: 600 !important;
}

.custom-input:focus, .custom-input:hover {
    background: #f3f3f3 !important;
}

.custom-input.error {
  border: 1px solid red !important;
}

.custom-input:disabled, .custom-input.disabled, .custom-input[disabled] {
    cursor: not-allowed;
    opacity: 0.5 !important;
}

.cp {
   cursor: pointer;
}


@media screen and (max-width: 991px) {
    *,*::before,*::after {
      -webkit-tap-highlight-color: transparent;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -webkit-overflow-scrolling: auto;
    }
    /* *::-webkit-scrollbar {
      width: 0px;
    } */
}

.footer {
  padding: 10px 0;
}
.logo-mis::before {
  content: 'LAMCO ';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
}
.logo-mis::after {
  content: '';
  width: 45px;
  height: 18px;
  background: url(./assets/mislogo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 5px;
}

.logo-mis {
    opacity: 0.4;
    display: flex;
    flex-direction: row;
}
.love::after {
  content: '❤️';
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  font-size: 15px;
}

input.form-control, select.form-control {
  border: 0;
  background: #f9f9f9 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

input.form-control:focus, input.form-control:active {
  background: #f1f1f1;
}

input.form-control::placeholder {
  color: #c9c9c9;
  text-transform: capitalize;
}

.input-icon.icon-color-primary .icon-addon {
    color: var(--primary-color);
}

.ks-footer {
    border-top: 1px solid #f9f9f9;
}

.ks-navigation.ks-browse {
    border-right: 1px solid #f9f9f9;
}

.ks-mail>.ks-emails>.ks-header, .table thead th {
    border-bottom: 1px solid #f9f9f9 !important;
}

.alert {
  border: 0;
  font-weight: 500;
  font-size: 12px;
}

.alert>.close>span {
    font-size: 33px;
    font-weight: 700;
    opacity: 0.6;
}

.ks-navbar .nav-item.ks-active, .ks-navbar .nav-item.show, .ks-navbar .nav-item:hover, .ks-navbar-logo:hover {
    background: transparent !important;
}

.ks-navbar-menu .nav-link {
    padding: 0 3px;
}

.ks-navbar-menu .nav-item span {
    padding: 0 10px;
    border-radius: 3px;
    transition: 0.3s ease-in-out;

    width: 100%;
    height: 60%;
    align-items: center;
    display: flex;
}

.ks-navbar-menu .nav-item.ks-active span, .ks-navbar-menu .nav-item span:hover{
    background: #212831 !important;
}

.ks-page-header .ks-title h3 {
  font-weight: 600 !important;
  opacity: 0.8 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}

.badge {
    font-size: 8px !important;
}

.form-control:disabled, .form-control:disabled:active, .form-control:disabled:hover {
  opacity: 0.4;
  border: 0;
}

.b-0 {
  border: 0;
}

.br-0 {
  border-radius: 0 !important;
}


th, td {
    white-space: nowrap !important;
}

.overflow-auto {
  overflow: auto !important;
}

.fc-h-event .fc-event-title-container {
    cursor: pointer !important;
}

.ks-mobile-overlay {
  z-index: 10;
}
.ks-right-bar {
  z-index: 999 !important;
}

.icon-addon .la {
    font-size: 20px !important;
}

.dropdown-title {
    padding: 10px 14px;
    font-weight: 600;
    color: #525252;
    border-radius: 0;
    background: #eee;
    pointer-events: none;
}

.dropdown .dropdown-menu .dropdown-item {
    white-space: break-spaces;
}

a[disabled], a:disabled, a.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.4 !important;
}


.modal-fs .modal-dialog {
  height: 100%;
  margin: 0;
  padding: 10px;
  max-width: 100%;
  border-radius: 10px;
}

.modal-fs .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey; 
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey; 
}
</style>
