import { createApp } from 'vue'
import { createStore } from 'vuex'

import App from './App.vue'
import router from './router';
import VueAxios from 'vue-axios';
import axios from '@/components/axios';
import {VueCookieNext} from 'vue-cookie-next';
import qs from 'qs';
import JLAlert from '@/components/jl-alert';
import JLButton from '@/components/jl-button';

// import PrimeVue from 'primevue/config';
// import Dialog from 'primevue/dialog';
// import DialogService from 'primevue/dialogservice';


// import "primevue/resources/primevue.min.css";
// import "primevue/resources/primevue.min.css";

import moment from 'moment';

import mitt from 'mitt';
const emitter = mitt();

const title = process.env.VUE_APP_APPNAME;
 
import API from './components/api';
import HELPER from './components/helper';



router.beforeEach((to, from, next) => {
    document.title = (to.meta.title ? to.meta.title : title);
    if(to.meta.auth == true && (!VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEAUTH) || !VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEXID)) ) {
        return next('/login');
    }
    else if(to.meta.auth == false && VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEAUTH) && VueCookieNext.isCookieAvailable(process.env.VUE_APP_COOKIEXID)) {
        return next('/');
    }
    return next();
});
//Store Configuration
const store = createStore({
    state() {
        return {
            user_detail : { }
        }
    },
    getters:{
        getname(state){
            return state.user_detail.name
        },
        getpriveledge(state){
            return state.user_detail.priveledge
        },
        getDetails(state){
            return state.user_detail
        }
    },
    mutations: {
        StoreDetail(state,payload){
            state.user_detail = payload.user_detail
        }
    }
})


const app = createApp(App);

// app.component('Dialog', Dialog);
app.config.globalProperties.appname = title;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$qs = qs;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.API = API;
app.config.globalProperties.HELPER = HELPER;



// app.use(PrimeVue, { ripple: true });
// app.use(DialogService);
app.use(store)
app.use(VueAxios, axios);
app.use(VueCookieNext);
app.use(JLAlert);
app.use(JLButton);
app.use(router);
app.mount('#app');
