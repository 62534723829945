<template>
    <button class="jlbutton" :class="{'jlloading':loading[id]}" :disabled="loading[id]" @click="triggerClick($event)">
        <slot v-if="!loading[id]" />
        <span v-else><slot /></span>
    </button>
</template>

<script>
import JLButton from './index.js';
export default {
    props: {
        className: {
            default: '',
            type: String
        },
        eventClick: {},
        params: {}
    },
    data() {
        return {
            loading: {},
            id: Date.now()
        }
    },
    methods: {
        triggerClick(e) {
            e.preventDefault();
            let id = this.id;
            this.loading[id] = true;

            if(typeof this.eventClick === 'function') {
                this.eventClick(id, this.params);
            }
        }
    },
    mounted() {
        JLButton.emitter.on('jlunload',(e) => {
            this.loading[e] = false;
        });
    }
}
</script>

<style scoped>
.jlbutton[disabled] {
    opacity: 1 !important;
    cursor: not-allowed;
    pointer-events: none !important;
}

.jlbutton {
    position: relative;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 5px;
}
.jlbutton.jlloading {
     border: #ffffff7a;
}

.jlloading:before {
  content: '';
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff7a;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.jlloading:after {
  content: '';
  position: absolute;
  display: flex;
  width: 30px;
  height: 30px;
  vertical-align: text-bottom;
  border: .25em solid #00000036;
  border-right-color: #ffffff1c;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.jlloading, .jlloading * {
    color: transparent;
}

@-webkit-keyframes spinner-border {
  to {
      -webkit-transform:rotate(360deg);transform:rotate(360deg)
    }
}

@keyframes spinner-border {
  to {
    -webkit-transform:rotate(360deg);transform:rotate(360deg)
  }
}

</style>