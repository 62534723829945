import AlertJLView from './JLAlert.vue';
import mitt from 'mitt';
const emitter = mitt();

const JLAlert = {
    install(Vue) {
        Vue.component('jl-alert', AlertJLView);
        Vue.config.globalProperties.$jlalert = {
            show(params, error = true) {
                emitter.emit('show', [params,error]);
            }
        }
    },
    emitter
}

export default JLAlert